html {
  margin: 0;
  padding: 0;
}

body {
  background-color: rgba(168, 142, 110, 0.425) !important;
}

.index-row {
  margin: 0px auto;
  padding: 20px 0px;
}

.form-space {
  padding: 2%;
  margin: 8%;
}

.card-border {
  border: 5px solid rgb(247, 147, 30);
  background-color: rgba(253, 166, 66, 0.123);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.line {
  border-top: 5px solid rgb(247, 147, 30);
}

.logo {
  mix-blend-mode: multiply;
}

.button {
	display: inline-block;
	border-radius: 10px;
	background-color: rgba(168, 142, 110, 0);
	border: 3px solid rgb(247, 147, 30);
	color: rgb(156, 85, 4);
	text-align: center;
	font-size: 20px;
	padding: 8px;
	width: 60%;
	transition: all 0.5s;
	cursor: pointer;
	margin: 5px;
}

.button:hover {
	opacity: 1;
  right: 0;
  color: rgb(53, 52, 52);
  background-color: rgba(247, 146, 30, 0.459);
  box-shadow: 0 10px 14px 0 rgba(0,0,0,0.24), 0 15px 45px 0 rgba(0,0,0,0.19);
}

.delete {
  background-color: rgba(168, 142, 110, 0);
	border: 3px solid rgb(248, 54, 20);
	color: rgb(156, 27, 4);
}

.table-head {
  background-color: #F69B2F;
}

.table {
  border: 3px solid black;
}

.my-custom-scrollbar {
  position: relative;
  height: 600px;
  overflow: auto;
}

.my-custom-scrollbar-less {
  position: relative;
  height: 400px;
  overflow: auto;
}

.table-wrapper-scroll-y {
display: block;
}

.radioLabel {
  font-size: larger;
}

.link-button {
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: rgb(66, 57, 43);
  text-decoration: underline;
  cursor: pointer;
}

	
.big {
  width: 1.2em;
  height: 1.2em;
}

.green {
  border: 5px solid rgb(37, 143, 10);
}
